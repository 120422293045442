import React from "react";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import CircleIcon from "@mui/icons-material/Circle";
import PanToolIcon from "@mui/icons-material/PanTool";
import GButton from "../../../../facade/g-button";
import "../../constructions.scss";
import { updateConstruction } from "../../../../services/constructions";

const GridConstructions = (props) => {
  const columns = [
    {
      field: "name",
      headerName: "Nombre",
      flex: 0.3,
      minWidth: 180,
    },
    {
      field: "location",
      headerName: "Dirección",
      flex: 0.2,
      minWidth: 150,
    },
    {
      field: "budget",
      headerName: "Presupuesto",
      flex: 0.1,
      minWidth: 100,
      renderCell: (val) => val.row.budget + " €",
    },
    {
      field: "name_city",
      headerName: "Municipio",
      flex: 0.1,
      minWidth: 100,
      renderCell: (val) => val.row.city.name,
    },
    {
      field: "name_customer",
      headerName: "Cliente",
      flex: 0.2,
      minWidth: 100,
      renderCell: (val) => val.row.customer.name,
    },
    {
      field: "active",
      headerName: "Activa",
      type: "number",
      flex: 0.05,
      minWidth: 80,
      renderCell: (val) =>
        val.row.active ? (
          <GButton
            iconButton={true}
            id={"active"}
            icon={<CircleIcon className="circle-color" />}
            onClick={() => {
              handleUpdateConstruction(0, val.row);
            }}
          />
        ) : (
          <GButton
            iconButton={true}
            id={"non_active"}
            icon={<PanToolIcon className="hand-color" />}
            onClick={() => {
              handleUpdateConstruction(1, val.row);
            }}
          />
        ),
    },
    {
      field: "actions",
      headerName: "Opciones",
      flex: 0.05,
      minWidth: 100,
      renderCell: (val) => {
        return (
          <div className="row-center">
            <GButton
              iconButton={true}
              id={"Edit"}
              icon={<EditIcon />}
              onClick={() => {
                props.setSelectedConstruction(val.row);
                props.setOpenUpdateConstruction(true);
              }}
            />
          </div>
        );
      },
    },
  ];

  const handleUpdateConstruction = async (active, construction) => {
    updateConstruction({ ...construction, active }).then((cnt) => {
      props.setConstructions(
        props.constructions.map((ct) =>
          ct.id === construction.id ? { ...construction, active } : ct
        )
      );
    });
  };

  return (
    <Box
      sx={{
        height: "80vh",
        width: "90%",
      }}
    >
      <DataGrid
        rows={props.constructions || []}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
      />
    </Box>
  );
};

export default GridConstructions;
