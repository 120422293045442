import React, { useEffect, useState } from "react";
import { getImpressionTypes, createPDF } from "../../services/print";
import { getMonths } from "../../services/masters";
import { Grid } from "@mui/material";
import { getEmployees } from "../../services/employees";
import GAutocomplete from "../../facade/g-autocomplete";
import DownloadIcon from "@mui/icons-material/Download";
import GDate from "../../facade/g-date";
import GButton from "../../facade/g-button";
import {
  createPDFAnticipation,
  createPDFCostsHours,
  createPDFCustom,
  createPDFDiets,
  createPDFPaysheet,
} from "../../pdf/pdf";
import GInput from "../../facade/g-input";
import { LocalPrintshop } from "@mui/icons-material";

const INITIAL_FORM_STATE = {
  employees: [],
  date_start: null,
  date_end: null,
  impression_type: null,
  concept: null,
  quantity: null,
  month: null,
  year: null,
};

const years = [
  {
    id: 2022,
    name: "2022",
  },
  {
    id: 2023,
    name: "2023",
  },
  {
    id: 2024,
    name: "2024",
  },
  {
    id: 2025,
    name: "2025",
  },
  {
    id: 2026,
    name: "2026",
  },
];

const Print = () => {
  const [impression_types, setImpressionTypes] = useState([]);
  const [impression, setImpression] = useState(INITIAL_FORM_STATE);
  const [employees, setEmployees] = useState([]);
  const [months, setMonths] = useState([]);

  useEffect(() => {
    getImpressionTypes().then((its) => setImpressionTypes(its));
    getEmployees().then((emps) => setEmployees(emps));
    getMonths().then((ms) => setMonths(ms));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    impression.impression_type?.id === 4
      ? createPDFCustom(impression)
      : createPDF(impression).then((info) => {
          if (Object.keys(info).length > 0) {
            selectPDFCreator(impression.impression_type, info);
          }
        });
  };

  const selectPDFCreator = (type, info) => {
    switch (type.id) {
      case 1:
        createPDFDiets(info, impression);
        break;
      case 2:
        createPDFPaysheet(info, impression);
        break;
      case 3:
        createPDFAnticipation(info, impression);
        break;
      case 5:
        createPDFCostsHours(info, impression);
        break;
      default:
        break;
    }
  };

  return (
    <form onSubmit={handleSubmit} method="post">
      <Grid container p={2} spacing={2}>
        <Grid item xs={12} className="row-start row-center">
          <LocalPrintshop className="logo-title" />
          <h1 className="m0">Imprimir</h1>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <GAutocomplete
                multiple={false}
                id="impression_type"
                label="Tipo de impresión"
                value={impression.impression_type || null}
                options={impression_types}
                required={true}
                onChange={(e, value) => {
                  setImpression({
                    ...INITIAL_FORM_STATE,
                    impression_type: value,
                  });
                }}
              />
            </Grid>
            {impression.impression_type?.id === 4 && (
              <Grid item xs={12}>
                <GInput
                  id="quantity"
                  name="quantity"
                  label="Cantidad"
                  type="number"
                  required={false}
                  value={impression.quantity}
                  onChange={(e) => {
                    setImpression({ ...impression, quantity: e.target.value });
                  }}
                  className="w-100"
                />
              </Grid>
            )}
            {impression.impression_type?.id === 2 ? (
              <>
                {" "}
                <Grid item xs={12} md={6}>
                  <GAutocomplete
                    multiple={false}
                    id="month"
                    label="Mes"
                    required={true}
                    value={impression.month || null}
                    options={months}
                    onChange={(e, value) => {
                      setImpression({ ...impression, month: value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <GAutocomplete
                    multiple={false}
                    id="year"
                    label="Año"
                    required={true}
                    value={impression.year || null}
                    options={years}
                    onChange={(e, value) => {
                      setImpression({ ...impression, year: value });
                    }}
                  />
                </Grid>
              </>
            ) : (
              <>
                {" "}
                <Grid item xs={12} md={6}>
                  <GDate
                    id="date_start"
                    name="date_start"
                    label="Desde"
                    required={true}
                    value={impression.date_start}
                    onChange={(e) => {
                      setImpression({
                        ...impression,
                        date_start:
                          e.target.value === "" ? null : e.target.value,
                      });
                    }}
                    className="w-100"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <GDate
                    id="date_end"
                    name="date_end"
                    label="Hasta"
                    required={true}
                    value={impression.date_end}
                    onChange={(e) => {
                      setImpression({
                        ...impression,
                        date_end: e.target.value === "" ? null : e.target.value,
                      });
                    }}
                    className="w-100"
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <GAutocomplete
                multiple={true}
                id="employees"
                label="Trabajadores"
                required={true}
                value={impression.employees || null}
                options={employees}
                onChange={(e, value) => {
                  setImpression({ ...impression, employees: value });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {impression.impression_type?.id !== 1 &&
          impression.impression_type?.id !== 2 &&
          impression.impression_type?.id !== 5 && (
            <Grid item xs={12}>
              <GInput
                id="concept"
                name="concept"
                label="Concepto"
                required={false}
                multiline={true}
                rows={4}
                value={impression.concept}
                onChange={(e) =>
                  setImpression({ ...impression, concept: e.target.value })
                }
                className="w-100"
              />
            </Grid>
          )}
        <Grid item xs={12} className="w-100 row-end">
          <GButton
            id={"send-button"}
            text={"Crear PDF"}
            icon={<DownloadIcon />}
            type={"submit"}
            iconButton={false}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default Print;
