import SendIcon from "@mui/icons-material/Send";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { getCostTypes } from "../../../../../services/masters";
import { getConstructions } from "../../../../../services/constructions";
import { getEmployees } from "../../../../../services/employees";
import GAutocomplete from "../../../../../facade/g-autocomplete";
import GInput from "../../../../../facade/g-input";
import GCheckBox from "../../../../../facade/g-checkbox";
import GButton from "../../../../../facade/g-button";
import GModal from "../../../../../facade/g-modal";
import GDate from "../../../../../facade/g-date";
import { createCostHour } from "../../../../../services/costs";

const INITIAL_FORM_STATE = {
  cost_type: null,
  date: new Date().toISOString().substring(0, 10),
  employees: [],
  construction: null,
  hours: null,
  observations: null,
  has_diet: false,
};

const ModalCreateCostHour = (props) => {
  const [cost, setCost] = useState(INITIAL_FORM_STATE);
  const [cost_types, setCostTypes] = useState([]);
  const [constructions, setConstructions] = useState([]);
  const [employees, setCosts] = useState([]);

  const handleChange = (event) => {
    setCost((prev_values) => ({
      ...prev_values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    createCostHour(cost).then((response) => {
      setCost(INITIAL_FORM_STATE);
      props.onClose(response.costs.total);
    });
  };

  useEffect(() => {
    props.open && getCostTypes().then((types) => setCostTypes(types));
    props.open &&
      getConstructions(1).then((constructions) =>
        setConstructions(constructions)
      );
    props.open && getEmployees(1).then((employees) => setCosts(employees));
  }, [props.open]);

  return (
    <GModal
      open={props.open}
      onClose={props.onClose}
      size={"large"}
      content={
        <form onSubmit={handleSubmit} method="post">
          <Grid
            container
            spacing={2}
            className="w-100 create-employee-container-modal"
          >
            <Grid item xs={12}>
              <GAutocomplete
                multiple={false}
                id="cost_type"
                label="Tipo"
                value={cost.cost_type || null}
                options={cost_types}
                required={true}
                onChange={(e, value) => {
                  setCost({ ...cost, cost_type: value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <GAutocomplete
                multiple={true}
                id="employees"
                label="Trabajador/es"
                value={cost.employees || null}
                options={employees}
                required={true}
                onChange={(e, value) => {
                  setCost({ ...cost, employees: value });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GDate
                id="date"
                name="date"
                label="Fecha"
                required={true}
                value={cost.date}
                onChange={(e) => handleChange(e)}
                className="w-100"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <GAutocomplete
                multiple={false}
                id="construction"
                label="Obra"
                value={cost.construction || null}
                options={constructions}
                required={true}
                onChange={(e, value) => {
                  setCost({ ...cost, construction: value });
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <GInput
                id="hours"
                name="hours"
                label="Horas"
                required={true}
                type={"number"}
                value={cost.hours}
                onChange={(e) => handleChange(e)}
                className="w-100"
              />
            </Grid>
            <Grid item xs={12}>
              <GInput
                id="observations"
                name="observations"
                label="Observaciones"
                required={false}
                multiline={true}
                rows={4}
                value={cost.observations}
                onChange={(e) => handleChange(e)}
                className="w-100"
              />
            </Grid>
            <Grid item xs={12} className="row-end">
              <GCheckBox
                id={"has_diet"}
                name={"has_diet"}
                label={"Dieta"}
                checked={cost.has_diet}
                onChange={(e) =>
                  setCost({ ...cost, has_diet: e.target.checked })
                }
              />
            </Grid>
            <Grid item xs={12} className="w-100 row-end">
              <GButton
                id={"send-button"}
                text={"Crear coste"}
                icon={<SendIcon />}
                type={"submit"}
                iconButton={false}
              />
            </Grid>
          </Grid>
        </form>
      }
      title={"Registrar coste de horas"}
    />
  );
};

export default ModalCreateCostHour;
