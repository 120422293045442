import SendIcon from "@mui/icons-material/Send";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import GAutocomplete from "../../../../facade/g-autocomplete";
import GButton from "../../../../facade/g-button";
import GInput from "../../../../facade/g-input";
import GModal from "../../../../facade/g-modal";
import { createEmployees } from "../../../../services/employees";
import { getJobs } from "../../../../services/masters";

const INITIAL_FORM_STATE = {
  name: null,
  hour_salary: null,
  saturday_salary: null,
  diet_salary: null,
  extra_hour_salary: null,
  festive_hour_salary: null,
  phone_number: null,
  job: null,
  active: 1,
};

const ModalCreateEmployee = (props) => {
  const [employee, setEmployee] = useState(INITIAL_FORM_STATE);
  const [jobs, setJobs] = useState([]);

  const handleChange = (event) => {
    setEmployee((prev_values) => ({
      ...prev_values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    createEmployees(employee).then((response) => {
      setEmployee(INITIAL_FORM_STATE);
      props.onClose({ ...employee, id: response.employee.id });
    });
  };

  useEffect(() => {
    props.open && getJobs().then((jobs) => setJobs(jobs));
  }, [props.open]);

  return (
    <GModal
      open={props.open}
      onClose={props.onClose}
      size={"large"}
      content={
        <form onSubmit={handleSubmit} method="post">
          <Grid
            container
            spacing={2}
            className="w-100 create-employee-container-modal"
          >
            <Grid item xs={12}>
              <p>Datos</p>
            </Grid>
            <Grid item xs={12} md={6}>
              <GInput
                id="name"
                name="name"
                label="Nombre"
                required={true}
                value={employee.name}
                onChange={(e) => handleChange(e)}
                className="w-100"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <GInput
                id="phone_number"
                name="phone_number"
                label="Número de teléfono"
                required={true}
                value={employee.phone_number}
                onChange={(e) => handleChange(e)}
                className="w-100"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <GAutocomplete
                multiple={false}
                id="job"
                label="Puesto de trabajo"
                value={employee.job || null}
                options={jobs}
                required={true}
                onChange={(e, value) => {
                  setEmployee({ ...employee, job: value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <p>Salarios</p>
            </Grid>
            <Grid item xs={12} md={2.4}>
              <GInput
                id="hour_salary"
                name="hour_salary"
                label="Salario por hora normal"
                required={true}
                type={"number"}
                value={employee.hour_salary}
                onChange={(e) => handleChange(e)}
                className="w-100"
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <GInput
                id="saturday_salary"
                name="saturday_salary"
                label="Salario por hora sábado"
                required={true}
                type={"number"}
                value={employee.saturday_salary}
                onChange={(e) => handleChange(e)}
                className="w-100"
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <GInput
                id="extra_hour_salary"
                name="extra_hour_salary"
                label="Salario por hora extra"
                required={true}
                type={"number"}
                value={employee.extra_hour_salary}
                onChange={(e) => handleChange(e)}
                className="w-100"
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <GInput
                id="festive_hour_salary"
                name="festive_hour_salary"
                label="Salario por hora festivos"
                required={true}
                type={"number"}
                value={employee.festive_hour_salary}
                onChange={(e) => handleChange(e)}
                className="w-100"
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <GInput
                id="diet_salary"
                name="diet_salary"
                label="Sueldo dieta"
                required={true}
                type={"number"}
                value={employee.diet_salary}
                onChange={(e) => handleChange(e)}
                className="w-100"
              />
            </Grid>
            <Grid item xs={12} className="w-100 row-end">
              <GButton
                id={"send-button"}
                text={"Crear trabajador"}
                icon={<SendIcon />}
                type={"submit"}
                iconButton={false}
              />
            </Grid>
          </Grid>
        </form>
      }
      title={"Crear trabajador"}
    />
  );
};

export default ModalCreateEmployee;
