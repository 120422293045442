import { store } from "../store/store";
import { Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

export const getToken = () => {
  return store.getState().user.token;
};

export const launchNotifcation = (text, type) => {
  Store.addNotification({
    title: text,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 2000,
      onScreen: true,
    },
  });
};

export const BAR_CHART_OPTIONS = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
    },
  },
};

export const formatDate = (date) => {
  date = new Date(date);
  return (
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
  );
};
