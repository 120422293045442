import { TextField } from "@mui/material";
import React from "react";

const GDate = (props) => {
  return (
    <TextField
      id={props.id}
      name={props.name}
      label={props.label ?? null}
      value={props.value ?? ""}
      type={"date"}
      variant="filled"
      InputProps={{
        color: "dark",
      }}
      InputLabelProps={{
        shrink: true,
        style: { color: props.colorLabel ?? "#a6a6a6" },
      }}
      onChange={props.onChange}
      required={props.required ?? false}
      className={props.className ?? null}
    />
  );
};

export default GDate;
