import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import HandymanIcon from "@mui/icons-material/Handyman";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import GButton from "../../facade/g-button";
import { getConstructions } from "../../services/constructions";
import GridConstructions from "./components/constructions_grid/construction_grid";
import ModalCreateConstruction from "./components/modal_create_construction/modal_create_construction";
import ModalUpdateConstruction from "./components/modal_update_construction/modal_update_construction";
import ModalCreateCustomer from "./components/modal_create_customer/modal_create_customer";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import "./constructions.scss";
import { getCustomers } from "../../services/customers";
import ModalListCustomer from "./components/modal_list_customer/modal_list_customer";
import ModalUpdateCustomer from "./components/modal_update_customer/modal_update_customer";

const Constructions = () => {
  const [constructions, setConstructions] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [open_create_construction, setOpenCreateConstruction] = useState(false);
  const [open_create_customer, setOpenCreateCustomer] = useState(false);
  const [open_list_customer, setOpenListCustomer] = useState(false);
  const [selected_construction, setSelectedConstruction] = useState();
  const [selected_customer, setSelectedCustomer] = useState();
  const [open_update_construction, setOpenUpdateConstruction] = useState(false);
  const [open_update_customer, setOpenUpdateCustomer] = useState(false);

  const handleCloseCreateConstruction = (construction) => {
    construction &&
      setConstructions(
        [
          {
            ...construction,
            name_customer: construction.customer.name,
            name_city: construction.city.name,
          },
        ].concat(constructions)
      );
    setOpenCreateConstruction(false);
  };

  const handleCloseUpdateConstruction = (construction) => {
    construction &&
      setConstructions(
        constructions.map((con) =>
          construction.id === con.id ? construction : con
        )
      );
    setOpenUpdateConstruction(false);
  };

  const handleCloseUpdateCustomer = (customer) => {
    customer &&
      setCustomers(
        customers.map((cus) => (customer.id === cus.id ? customer : cus))
      );
    setOpenUpdateCustomer(false);
  };

  const handleOpenUpdateCustomer = (customer) => {
    customer && setOpenUpdateCustomer(true);
    customer && setSelectedCustomer(customer);
  };

  useEffect(() => {
    getConstructions().then((constrs) => setConstructions(constrs));
    getCustomers().then((custs) => setCustomers(custs));
  }, []);

  return (
    <Grid container rowSpacing={2} p={2}>
      <Grid item xs={12} md={6} className="row-start row-center">
        <HandymanIcon className="logo-title" />
        <h1 className="m0">Obras</h1>
      </Grid>
      <Grid item xs={12} md={6} className="row-end ">
        <GButton
          id={"Create"}
          text={"Ver Clientes"}
          icon={<PersonSearchIcon />}
          onClick={() => setOpenListCustomer(true)}
          className="button-constructions"
        />
        <GButton
          id={"Create"}
          text={"Crear Cliente"}
          icon={<BookmarkAddIcon />}
          onClick={() => setOpenCreateCustomer(true)}
          className="button-constructions"
        />
        <GButton
          id={"Create"}
          text={"Crear Obra"}
          icon={<AddBusinessIcon />}
          onClick={() => setOpenCreateConstruction(true)}
        />
      </Grid>
      <Grid item xs={12} className="column-center">
        <GridConstructions
          constructions={constructions}
          setSelectedConstruction={setSelectedConstruction}
          setOpenUpdateConstruction={setOpenUpdateConstruction}
          setConstructions={setConstructions}
        />
      </Grid>
      <ModalCreateConstruction
        open={open_create_construction}
        onClose={handleCloseCreateConstruction}
      />
      <ModalCreateCustomer
        open={open_create_customer}
        onClose={() => setOpenCreateCustomer(false)}
      />
      <ModalUpdateConstruction
        open={open_update_construction}
        onClose={handleCloseUpdateConstruction}
        construction={selected_construction}
      />
      <ModalListCustomer
        open={open_list_customer}
        onClose={() => setOpenListCustomer(false)}
        customers={customers}
        handleOpenUpdateCustomer={handleOpenUpdateCustomer}
      />
      <ModalUpdateCustomer
        open={open_update_customer}
        onClose={handleCloseUpdateCustomer}
        customer={selected_customer}
      />
    </Grid>
  );
};

export default Constructions;
