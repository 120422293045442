import EditIcon from "@mui/icons-material/Edit";
import GButton from "../../../../facade/g-button";
import GModal from "../../../../facade/g-modal";
import { useEffect, useState } from "react";
import GInput from "../../../../facade/g-input";
import SearchIcon from "@mui/icons-material/Search";

const ModalListCustomer = (props) => {
  const [search, setSearch] = useState("");
  const [customers, setCustomers] = useState([]);

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
    setCustomers(filterCustomers(event.target.value));
  };

  const filterCustomers = (text) => {
    return props.customers?.filter((cust) =>
      cust.name.toLowerCase().includes(text.toLowerCase())
    );
  };

  const restartModalData = () => {
    setCustomers(props.customers);
    setSearch("");
  };

  const closeModal = () => {
    restartModalData();
    props.onClose();
  };

  useEffect(() => {
    props.open && restartModalData();
  }, [props.open, props.customers]);

  return (
    <GModal
      open={props.open}
      onClose={closeModal}
      size={"small"}
      content={
        <div className="row start w-100">
          <GInput
            id="search"
            name="search"
            label="Buscar"
            value={search}
            onChange={(e) => handleChangeSearch(e)}
            className="w-100"
            endAdornment={<SearchIcon />}
          />
          <div className="modal-list-customers-content">
            {customers?.length > 0 ? (
              customers.map((customer) => (
                <div
                  key={customer.id}
                  className="row-start w-100 customer-content"
                >
                  <p className="customer-name-list">{customer.name}</p>{" "}
                  <GButton
                    iconButton={true}
                    id={"Edit"}
                    icon={<EditIcon />}
                    className={"edit-icon-customer"}
                    onClick={() => props.handleOpenUpdateCustomer(customer)}
                  />
                </div>
              ))
            ) : (
              <p>No hay clientes registrados.</p>
            )}
          </div>
        </div>
      }
      title={"Var Clientes"}
    />
  );
};

export default ModalListCustomer;
