import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

const GAutocomplete = (props) => {
  const [required, setRequired] = useState(props.required)

  const checkRequired = () => {
    if (props.required) {
      if (props.multiple) {
        setRequired(props.value.length === 0);
      } else {
        setRequired(true);
      }
    } else {
      setRequired(false);
    }
  };

  useEffect(() => {
    checkRequired();
  }, [props.value]);

  return (
    <Autocomplete
      id={props.id}
      multiple={props.multiple}
      options={props.options}
      renderInput={(params) => (
        <TextField
          InputLabelProps={{ style: { color: props.colorLabel ?? "#a6a6a6" } }}
          InputProps={{
            color: "dark",
          }}
          {...params}
          label={props.label}
          variant="filled"
          required={required}
        />
      )}
      disabled={props.disabled ?? false}
      onChange={props.onChange}
      value={props.value}
      getOptionLabel={(option) => option.name}
    />
  );
};

export default GAutocomplete;
