import axios from "axios";
import { getToken, launchNotifcation } from "../common/common";
import "../config";

export const getEmployees = async (active) => {
  const config = {
    params: { active },
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .get(global.rute + "employees", config)
    .then((response) => {
      if (response) return response.data.data.employees;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const createEmployees = async (employee) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .post(global.rute + "employees", employee, config)
    .then((response) => {
      if (response) {
        launchNotifcation("Trabajador creado con éxito.", "success");
        return response.data.data;
      }
    })
    .catch((err) => {
      launchNotifcation("¡Ups! Se ha producido un error...", "danger");
      console.log(err);
      return err;
    });
};

export const getEmployee = async (id) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .get(global.rute + "employees/" + id, config)
    .then((response) => {
      if (response) return response.data.data.employee;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const updateEmployees = async (employee) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .put(global.rute + "employees", employee, config)
    .then((response) => {
      if (response) {
        launchNotifcation("Trabajador actualizado con éxito.", "success");
        return response.data.data.employee;
      }
    })
    .catch((err) => {
      launchNotifcation("¡Ups! Se ha producido un error...", "danger");
      console.log(err);
      return err;
    });
};
