import { Box, Modal } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./facade.scss";
import GButton from "./g-button";

const GModal = (props) => {
  return (
    <Modal open={props.open} onClose={() => props.onClose()}>
      <Box className={"modal-container " + props.size}>
        <div className="row-space-between modal-header">
          <h3 className="modal-title">{props.title}</h3>
          <div className="modal-cross">
            <GButton
              iconButton={true}
              icon={<CloseIcon />}
              onClick={() => props.onClose()}
              color={"white"}
            />
          </div>
        </div>
        <div className="modal-body">{props.content}</div>
      </Box>
    </Modal>
  );
};

export default GModal;
