import SendIcon from "@mui/icons-material/Send";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import GAutocomplete from "../../../../../facade/g-autocomplete";
import GButton from "../../../../../facade/g-button";
import GDate from "../../../../../facade/g-date";
import GInput from "../../../../../facade/g-input";
import GModal from "../../../../../facade/g-modal";
import { getConstructions } from "../../../../../services/constructions";
import { getEmployees } from "../../../../../services/employees";
import { updateCostHour } from "../../../../../services/costs";
import { getCostTypes } from "../../../../../services/masters";

const INITIAL_FORM_STATE = {
  id: null,
  cost_type: null,
  date: new Date().toISOString().substring(0, 10),
  employee: null,
  construction: null,
  hours: null,
  observations: null,
};

const ModalUpdateCostHours = (props) => {
  const [cost, setCost] = useState(INITIAL_FORM_STATE);
  const [constructions, setConstructions] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [cost_types, setCostTypes] = useState([]);

  const handleChange = (event) => {
    setCost((prev_values) => ({
      ...prev_values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateCostHour(cost).then((emp) => {
      props.onClose({ ...cost, id: props.cost.id });
    });
  };

  const selectType = (types, initial_cost) => {
    const data = initial_cost.id.split("_");
    const id = parseInt(data[0]);
    const type = parseInt(data[1]);
    const cost_type = types.find((t) => t.id === type);
    setCost({
      ...initial_cost,
      cost_type,
      id,
    });
  };

  useEffect(() => {
    const initial_cost = props.cost;
    props.open &&
      getCostTypes().then((types) => {
        setCostTypes(types);
        selectType(types, initial_cost);
      });
    props.open &&
      getConstructions(1).then((constructions) =>
        setConstructions(constructions)
      );
    props.open && getEmployees().then((employees) => setEmployees(employees));
  }, [props.open]);

  return (
    <GModal
      open={props.open}
      onClose={props.onClose}
      size={"large"}
      content={
        <form onSubmit={handleSubmit} method="post">
          <Grid
            container
            spacing={2}
            className="w-100 create-employee-container-modal"
          >
            <Grid item xs={12}>
              <GAutocomplete
                multiple={false}
                id="cost_type"
                label="Tipo"
                value={cost.cost_type || null}
                options={cost_types}
                required={true}
                onChange={(e, value) => {
                  setCost({ ...cost, cost_type: value });
                }}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12}>
              <GAutocomplete
                multiple={false}
                id="employee"
                label="Trabajador"
                value={cost.employee || null}
                options={employees}
                required={true}
                onChange={(e, value) => {
                  setCost({ ...cost, employee: value });
                }}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GDate
                id="date"
                name="date"
                label="Fecha"
                required={true}
                value={cost.date?.substring(0, 10) || null}
                onChange={(e) => handleChange(e)}
                className="w-100"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <GAutocomplete
                multiple={false}
                id="construction"
                label="Obra"
                value={cost.construction || null}
                options={constructions}
                required={true}
                onChange={(e, value) => {
                  setCost({ ...cost, construction: value });
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <GInput
                id="hours"
                name="hours"
                label="Horas"
                required={true}
                type={"number"}
                value={cost.hours}
                onChange={(e) => handleChange(e)}
                className="w-100"
              />
            </Grid>
            <Grid item xs={12}>
              <GInput
                id="observations"
                name="observations"
                label="Observaciones"
                required={false}
                multiline={true}
                rows={4}
                value={cost?.observations}
                onChange={(e) => handleChange(e)}
                className="w-100"
              />
            </Grid>
            <Grid item xs={12} className="w-100 row-end">
              <GButton
                id={"send-button"}
                text={"Editar coste"}
                icon={<SendIcon />}
                type={"submit"}
                iconButton={false}
              />
            </Grid>
          </Grid>
        </form>
      }
      title={"Editar coste"}
    />
  );
};

export default ModalUpdateCostHours;
