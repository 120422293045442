import SendIcon from "@mui/icons-material/Send";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import GAutocomplete from "../../../../../facade/g-autocomplete";
import GButton from "../../../../../facade/g-button";
import GDate from "../../../../../facade/g-date";
import GInput from "../../../../../facade/g-input";
import GModal from "../../../../../facade/g-modal";
import { getConstructions } from "../../../../../services/constructions";
import { getEmployees } from "../../../../../services/employees";
import { createCostOthers } from "../../../../../services/costs";
import { getCostTypesOthers } from "../../../../../services/masters";

const INITIAL_FORM_STATE = {
  date: new Date().toISOString().substring(0, 10),
  employees: [],
  cost_type: null,
  construction: null,
  observations: null,
  salary: null,
};

const ModalCreateCostOthers = (props) => {
  const [cost, setCost] = useState(INITIAL_FORM_STATE);
  const [constructions, setConstructions] = useState([]);
  const [employees, setCosts] = useState([]);
  const [cost_types_others, setCostTypesOthers] = useState([]);

  const handleChange = (event) => {
    setCost((prev_values) => ({
      ...prev_values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    createCostOthers(cost).then((response) => {
      setCost(INITIAL_FORM_STATE);
      props.onClose(response.costs.total);
    });
  };

  useEffect(() => {
    props.open &&
      getConstructions(1).then((constructions) =>
        setConstructions(constructions)
      );
    props.open && getEmployees(1).then((employees) => setCosts(employees));
    props.open &&
      getCostTypesOthers().then((employees) => setCostTypesOthers(employees));
  }, [props.open]);

  return (
    <GModal
      open={props.open}
      onClose={props.onClose}
      size={"large"}
      content={
        <form onSubmit={handleSubmit} method="post">
          <Grid
            container
            spacing={2}
            className="w-100 create-employee-container-modal"
          >
            <Grid item xs={12} md={8}>
              <GAutocomplete
                multiple={true}
                id="employees"
                label="Trabajador/es"
                value={cost.employees || null}
                options={employees}
                required={true}
                onChange={(e, value) => {
                  setCost({ ...cost, employees: value });
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <GAutocomplete
                multiple={false}
                id="construction"
                label="Obra"
                value={cost.construction || null}
                options={constructions}
                required={false}
                onChange={(e, value) => {
                  setCost({ ...cost, construction: value });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GDate
                id="date"
                name="date"
                label="Fecha"
                required={true}
                value={cost.date}
                onChange={(e) => handleChange(e)}
                className="w-100"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <GAutocomplete
                multiple={false}
                id="cost_types"
                label="Tipo"
                value={cost.cost_type || null}
                options={cost_types_others}
                required={true}
                onChange={(e, value) => {
                  setCost({ ...cost, cost_type: value });
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <GInput
                id="salary"
                name="salary"
                type="number"
                label="Sueldo"
                required={true}
                value={cost.salary}
                onChange={(e) => handleChange(e)}
                className="w-100"
              />
            </Grid>
            <Grid item xs={12}>
              <GInput
                id="observations"
                name="observations"
                label="Observaciones"
                required={false}
                multiline={true}
                rows={4}
                value={cost.observations}
                onChange={(e) => handleChange(e)}
                className="w-100"
              />
            </Grid>
            <Grid item xs={12} className="w-100 row-end">
              <GButton
                id={"send-button"}
                text={"Crear coste"}
                icon={<SendIcon />}
                type={"submit"}
                iconButton={false}
              />
            </Grid>
          </Grid>
        </form>
      }
      title={"Registrar otro tipo de coste"}
    />
  );
};

export default ModalCreateCostOthers;
