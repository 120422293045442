import PostAddIcon from "@mui/icons-material/PostAdd";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import GButton from "../../../../facade/g-button";
import { getCosts } from "../../../../services/costs";
import "../../costs.scss";
import CostDietGrid from "./cost_diet_grid/cost_diet_grid";
import ModalCreateCostDiet from "./modal_create_cost_diet/modal_create_cost_diet";
import ModalDeleteCostDiets from "./modal_delete_cost_diets/modal_delete_cost_diets";
import ModalUpdateCostDiets from "./modal_update_cost_diets/modal_update_cost_diets";

const CostsDiets = () => {
  const [costs, setCosts] = useState([]);
  const [open_create_cost_diet, setOpenCreateCostDiet] = useState(false);
  const [selected_cost_diets, setSelectedCostDiets] = useState();
  const [open_delete_cost_diets, setOpenDeleteCostDiets] = useState(false);
  const [open_update_cost_diets, setOpenUpdateCostDiets] = useState(false);

  const handleCloseCreateCost = (total) => {
    total && getCosts(2).then((costs) => setCosts(costs));
    setOpenCreateCostDiet(false);
  };

  const handleCloseDeleteCost = (id) => {
    id && setCosts(costs.filter((cos) => cos.id !== id));
    setOpenDeleteCostDiets(false);
  };

  const handleCloseUpdateCost = (cost) => {
    cost && setCosts(costs.map((cos) => (cost.id === cos.id ? cost : cos)));
    setOpenUpdateCostDiets(false);
  };

  useEffect(() => {
    getCosts(2).then((costs) => setCosts(costs));
  }, []);

  return (
    <Grid container rowSpacing={2} p={2}>
      <Grid item xs={12} className="row-end">
        <GButton
          id={"create_diet"}
          text={"Crear dieta"}
          icon={<PostAddIcon />}
          onClick={() => setOpenCreateCostDiet(true)}
        />
      </Grid>
      <Grid item xs={12} className="column-center">
        <CostDietGrid
          costs={costs}
          setSelectedCostDiets={setSelectedCostDiets}
          setOpenUpdateCostDiets={setOpenUpdateCostDiets}
          setOpenDeleteCostDiets={setOpenDeleteCostDiets}
        />
      </Grid>
      <ModalCreateCostDiet
        open={open_create_cost_diet}
        onClose={handleCloseCreateCost}
      />
      <ModalDeleteCostDiets
        open={open_delete_cost_diets}
        onClose={handleCloseDeleteCost}
        cost={selected_cost_diets}
      />
      <ModalUpdateCostDiets
        open={open_update_cost_diets}
        onClose={handleCloseUpdateCost}
        cost={selected_cost_diets}
      />
    </Grid>
  );
};

export default CostsDiets;
