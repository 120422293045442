import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeUser } from "../../actions/user-actions";
import LogoGaymar from "../../assets/logo/logo_gaymar.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyIcon from "@mui/icons-material/Key";
import GButton from "../../facade/g-button";
import SendIcon from "@mui/icons-material/Send";
import GInput from "../../facade/g-input";
import { loginUser } from "../../services/users";
import "./login.scss";

const Login = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [loginValues, setLoginValues] = useState({
    username: "",
    password: "",
  });

  const handleChange = (event) => {
    setError(null);
    setLoginValues((prev_values) => ({
      ...prev_values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    loginUser(loginValues).then((response) => {
      if (response.error) {
        setError(response.error);
      } else {
        dispatch(
          changeUser({
            token: response.data.token,
            user: response.data.user,
          })
        );
        navigate("/home", { replace: true });
      }
    });
  };

  return (
    <div className="login-main">
      <Grid container className="login-content">
        <Grid item xs={12} sm={12} md={6} className="login-item" style={{}}>
          <img src={LogoGaymar} alt="logo-gaymar" />
          <h2>Management</h2>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <form onSubmit={handleSubmit} method="post">
            <GInput
              id="username"
              name="username"
              label="Nombre de usuario"
              required={true}
              value={loginValues.username}
              onChange={(e) => handleChange(e)}
              endAdornment={<AccountCircleIcon />}
              className={"login-input"}
            />
            <GInput
              required={true}
              id="password"
              name="password"
              label="Contraseña"
              value={loginValues.password}
              type="password"
              onChange={(e) => handleChange(e)}
              endAdornment={<KeyIcon />}
              className={"login-input"}
            />
            <GButton
              id={"send-button"}
              text={"Iniciar Sesión"}
              icon={<SendIcon />}
              type={"submit"}
              iconButton={false}
              className={"login-button"}
            />
            {error && <p className="text-error">{error}</p>}
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
