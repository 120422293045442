import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useMemo, useState } from "react";
import GAutocomplete from "../../../../../facade/g-autocomplete";
import GButton from "../../../../../facade/g-button";
import GDate from "../../../../../facade/g-date";

const INIT_FILTERS = {
  employees: [],
  constructions: [],
  cost_types: [],
  date_from: null,
  date_to: null,
};

const CostOthersGrid = (props) => {
  const [costs, setCosts] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [constructions, setConstructions] = useState([]);
  const [cost_types, setCostTypes] = useState([]);
  const [filters, setFilters] = useState(INIT_FILTERS);

  const columns = [
    {
      field: "name_employee",
      headerName: "Trabajador",
      renderCell: (val) => val.row.employee.name,
      flex: 0.2,
      minWidth: 150,
    },
    {
      field: "name_construction",
      headerName: "Obra",
      renderCell: (val) => val.row.construction?.name ?? "",
      flex: 0.2,
      minWidth: 100,
    },
    {
      field: "salary",
      headerName: "Sueldo",
      renderCell: (val) => val.row.salary + " €",
      flex: 0.15,
      minWidth: 100,
    },
    {
      field: "date",
      headerName: "Fecha",
      renderCell: (val) => val.row.date.substring(0, 10),
      flex: 0.1,
      minWidth: 100,
    },
    {
      field: "name_cost_type",
      headerName: "Tipo",
      renderCell: (val) => val.row.cost_type.name,
      flex: 0.1,
      minWidth: 100,
    },
    {
      field: "observations",
      headerName: "Observaciones",
      flex: 0.1,
      minWidth: 100,
    },
    {
      field: "actions",
      headerName: "Opciones",
      flex: 0.1,
      minWidth: 100,
      renderCell: (val) => {
        return (
          <div className="row-center">
            <GButton
              iconButton={true}
              id={"Edit"}
              icon={<EditIcon />}
              onClick={() => {
                props.setSelectedCostOthers(val.row);
                props.setOpenUpdateCostOthers(true);
              }}
            />
            <GButton
              iconButton={true}
              color="#ff4d4d"
              id={"Delete"}
              icon={<DeleteIcon />}
              onClick={() => {
                props.setSelectedCostOthers(val.row);
                props.setOpenDeleteCostOthers(true);
              }}
            />
          </div>
        );
      },
    },
  ];

  const getEmployeesFromCosts = () => {
    if (props.costs.length > 0) {
      const emps = props.costs.map((c) => c.employee);
      return [...new Map(emps.map((m) => [m.id, m])).values()];
    } else return [];
  };

  const getConstructionsFromCosts = () => {
    if (props.costs.length > 0) {
      const consts = props.costs.map((c) => c.construction).filter((c) => c.id);
      return [...new Map(consts.map((m) => [m.id, m])).values()];
    } else return [];
  };

  const getCostTypesFromCosts = () => {
    if (props.costs.length > 0) {
      const cts = props.costs.map((c) => c.cost_type);
      return [...new Map(cts.map((m) => [m.id, m])).values()];
    } else return [];
  };

  const applyFilters = () => {
    var costs_filtered = props.costs;
    if (filters.constructions.length > 0) {
      costs_filtered = costs_filtered.filter((cf) =>
        filters.constructions.some((c) => c.id === cf.construction.id)
      );
    }
    if (filters.employees.length > 0) {
      costs_filtered = costs_filtered.filter((cf) =>
        filters.employees.some((c) => c.id === cf.employee.id)
      );
    }
    if (filters.cost_types.length > 0) {
      costs_filtered = costs_filtered.filter((cf) =>
        filters.cost_types.some((c) => c.id === cf.cost_type.id)
      );
    }
    if (filters.date_from !== null) {
      costs_filtered = costs_filtered.filter(
        (cf) => filters.date_from <= cf.date.substring(0, 10)
      );
    }
    if (filters.date_to !== null) {
      costs_filtered = costs_filtered.filter(
        (cf) => filters.date_to >= cf.date.substring(0, 10)
      );
    }
    setCosts(costs_filtered);
  };

  useMemo(() => {
    setEmployees(getEmployeesFromCosts());
    setConstructions(getConstructionsFromCosts());
    setCostTypes(getCostTypesFromCosts());
    setCosts(props.costs);
  }, [props.costs]);

  useMemo(() => {
    applyFilters();
  }, [filters, props.costs]);

  return (
    <Box
      sx={{
        height: "57vh",
        width: "90%",
      }}
    >
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={3}>
          <GAutocomplete
            multiple={true}
            id="filter_employee"
            label="Trabajadores"
            value={filters.employees || null}
            options={employees}
            onChange={(e, value) => {
              setFilters({ ...filters, employees: value });
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <GAutocomplete
            multiple={true}
            id="filter_construction"
            label="Obras"
            value={filters.constructions || null}
            options={constructions}
            onChange={(e, value) => {
              setFilters({ ...filters, constructions: value });
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <GAutocomplete
            multiple={true}
            id="cost_type"
            label="Tipos"
            value={filters.cost_types || null}
            options={cost_types}
            onChange={(e, value) => {
              setFilters({ ...filters, cost_types: value });
            }}
          />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <GDate
            id="date_from"
            name="date_from"
            label="Fecha desde"
            required={true}
            value={filters.date_from}
            onChange={(e) => {
              setFilters({
                ...filters,
                date_from: e.target.value === "" ? null : e.target.value,
              });
            }}
            className="w-100"
          />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <GDate
            id="date_to"
            name="date_to"
            label="Fecha hasta"
            required={true}
            value={filters.date_to}
            onChange={(e) => {
              setFilters({
                ...filters,
                date_to: e.target.value === "" ? null : e.target.value,
              });
            }}
            className="w-100"
          />
        </Grid>
      </Grid>
      <DataGrid
        rows={costs || []}
        columns={columns}
        pageSize={8}
        rowsPerPageOptions={[8]}
        disableSelectionOnClick
      />
    </Box>
  );
};

export default CostOthersGrid;
