import AddCardIcon from "@mui/icons-material/AddCard";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import GButton from "../../facade/g-button";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { getPayments } from "../../services/payments";
import GridPayments from "./components/payments_grid/payments_grid";
import ModalCreatePayment from "./components/modal_create_payment/modal_create_payment";
import ModalUpdatePayment from "./components/modal_update_payment/modal_update_payment";
import ModalDeletePayment from "./components/modal_delete_payment/modal_delete_payment";
import "./payments.scss";

const Payments = () => {
  const [payments, setPayments] = useState([]);
  const [selected_payment, setSelectedPayment] = useState();
  const [open_create_payment, setOpenCreatePayment] = useState(false);
  const [open_update_payment, setOpenUpdatePayment] = useState(false);
  const [open_delete_payment, setOpenDeletePayment] = useState(false);

  const handleCloseCreatePayment = (payment) => {
    payment && setPayments([payment].concat(payments));
    setOpenCreatePayment(false);
  };

  const handleCloseDeletePayment = (id) => {
    id && setPayments(payments.filter((cos) => cos.id !== id));
    setOpenDeletePayment(false);
  };

  const handleCloseUpdatePayment = (payment) => {
    payment &&
      setPayments(
        payments.map((pay) => (pay.id === payment.id ? payment : pay))
      );
    setOpenUpdatePayment(false);
  };

  useEffect(() => {
    getPayments().then((pays) => setPayments(pays));
  }, []);

  return (
    <Grid container rowSpacing={2} p={2}>
      <Grid item xs={12} md={6} className="row-start row-center">
        <PointOfSaleIcon className="logo-title" />
        <h1 className="m0">Jornales</h1>
      </Grid>
      <Grid item xs={12} md={6} className="row-end">
        <GButton
          id={"create"}
          text={"Crear Pago"}
          icon={<AddCardIcon />}
          onClick={() => setOpenCreatePayment(true)}
        />
      </Grid>
      <Grid item xs={12} className="column-center">
        <GridPayments
          payments={payments}
          openModalUpdate={open_update_payment}
          setOpenUpdatePayment={setOpenUpdatePayment}
          setSelectedPayment={setSelectedPayment}
          setOpenDeletePayment={setOpenDeletePayment}
        />
      </Grid>
      <ModalCreatePayment
        open={open_create_payment}
        onClose={handleCloseCreatePayment}
      />
      <ModalDeletePayment
        open={open_delete_payment}
        onClose={handleCloseDeletePayment}
        payment={selected_payment}
      />
      <ModalUpdatePayment
        open={open_update_payment}
        onClose={handleCloseUpdatePayment}
        payment={selected_payment}
      />
    </Grid>
  );
};

export default Payments;
