import { getToken } from "../common/common";
import axios from "axios";

export const getJobs = async () => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .get(global.rute + "masters/jobs", config)
    .then((response) => {
      if (response) return response.data.data.jobs;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const getCities = async () => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .get(global.rute + "masters/cities", config)
    .then((response) => {
      if (response) return response.data.data.cities;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const getCostTypes = async () => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .get(global.rute + "masters/cost-types", config)
    .then((response) => {
      if (response) return response.data.data.cost_types;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const getCostTypesOthers = async () => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .get(global.rute + "masters/cost-types-others", config)
    .then((response) => {
      if (response) return response.data.data.cost_types;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const getPaymentTypes = async () => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .get(global.rute + "masters/payment-types", config)
    .then((response) => {
      if (response) return response.data.data.payment_types;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const getMonths = async () => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .get(global.rute + "masters/months", config)
    .then((response) => {
      if (response) return response.data.data.months;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};
