import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { formatDate } from "../common/common";
import logo from "../assets/logo/logo_gaymar_docs.jpg";

export const createPDFDiets = (info, impression) => {
  var doc = new jsPDF("p", "px", "a4", false);
  info?.info?.forEach((diet, index) => {
    index > 0 && doc.addPage();
    var image = new Image();
    image.src = logo;
    doc.addImage(image, 330, 35, 75, 20);
    doc.setFontSize(20);
    doc.text(diet.employee.name, 50, 50);
    doc.setFontSize(10);
    doc.text("RECIBÍ DE: ESTRUCTURAS Y OBRAS JUARGON, S.L.", 50, 80);
    doc.text("CIF: B-42511709", 50, 100);
    doc.text("LA CANTIDAD: " + diet.quantity + "€", 50, 120);
    doc.text(
      "CONCEPTO DE: Gastos manutención por acuerdo de empresa ",
      50,
      140
    );
    doc.text(
      "correspondiente desde el " +
        formatDate(impression.date_start) +
        " al " +
        formatDate(impression.date_end) +
        ".",
      50,
      150
    );
    doc.text("CANTIDAD: //" + diet.quantity + "€//", 50, 190);
    doc.text(
      "IBI, a " +
        new Date().getDate() +
        "/" +
        (new Date().getMonth() + 1) +
        "/" +
        new Date().getFullYear(),
      320,
      190
    );
    doc.text("FDO: " + diet.employee.name.toUpperCase(), 220, 230);
  });
  doc.save(
    "dietas_" + impression.date_start + "a" + impression.date_end + ".pdf"
  );
};

export const createPDFPaysheet = (info, impression) => {
  var doc = new jsPDF("p", "px", "a4", false);
  for (let i = 0; i < impression.employees.length; i++) {
    i > 0 && doc.addPage();
    var image = new Image();
    image.src = logo;
    doc.addImage(image, 330, 35, 75, 20);
    doc.setFontSize(20);
    doc.text(info.cost[i]?.employee.name, 50, 50); //Siempre vienen costes
    doc.setFontSize(10);
    doc.text("RECIBÍ DE: ESTRUCTURAS Y OBRAS JUARGON, S.L.", 50, 80);
    doc.text("CIF: B-42511709", 50, 100);
    doc.text(
      "LIQUIDACIÓN MES: ", //+ impression.month.name.toUpperCase(),
      50,
      120
    );

    doc
      .setFont(undefined, "bold")
      .setFontSize(12)
      .text(impression.month.name.toUpperCase(), 126, 120)
      .setFontSize(10)
      .setFont(undefined, "normal");

    if (info.cost.length > 0) {
      doc.autoTable({
        body: info.cost[i]?.costs,
        startY: 150,
        margin: 70,
        theme: "plain",
        didParseCell: (HookData) => {
          if (
            HookData.row.index === 0 ||
            HookData.row.index === HookData.table.body.length - 1
          ) {
            HookData.cell.styles.fontStyle = "bold";
            HookData.cell.styles.fontSize = 12;
          }
        },
        columnStyles: {
          0: {
            halign: "left",
            cellWidth: 100,
          },
          1: {
            halign: "center",
            cellWidth: 70,
          },
          2: {
            halign: "center",
            cellWidth: 70,
          },
          3: {
            halign: "right",
            cellWidth: 70,
          },
        },
      });
    }

    if (info.pay.length > 0) {
      doc.text("ENTREGAS", 50, 310);
      doc.autoTable({
        body: info.pay[i]?.pays,
        startY: 320,
        margin: 70,
        theme: "plain",
        didParseCell: (HookData) => {
          if (
            HookData.row.index === 0 ||
            HookData.row.index === HookData.table.body.length - 1
          ) {
            HookData.cell.styles.fontStyle = "bold";
            HookData.cell.styles.fontSize = 12;
          }
        },
        columnStyles: {
          0: {
            halign: "left",
            cellWidth: 170,
          },
          1: {
            halign: "center",
            cellWidth: 70,
          },
          2: {
            halign: "right",
            cellWidth: 70,
          },
        },
      });
    }

    doc.text(
      "IBI, a " +
        new Date().getDate() +
        "/" +
        (new Date().getMonth() + 1) +
        "/" +
        new Date().getFullYear(),
      320,
      550
    );
    doc.text("FDO: " + info.cost[i]?.employee.name.toUpperCase(), 50, 550);
  }

  doc.save("nomina_" + impression.month.name + impression.year.name + ".pdf");
};

export const createPDFAnticipation = (info, impression) => {
  var doc = new jsPDF("p", "px", "a4", false);
  info?.info?.forEach((anticipation, index) => {
    index > 0 && doc.addPage();
    var image = new Image();
    image.src = logo;
    doc.addImage(image, 330, 35, 75, 20);
    doc.setFontSize(20);
    doc.text(anticipation.employee.name, 50, 50);
    doc.setFontSize(10);
    doc.text("RECIBÍ DE: ESTRUCTURAS Y OBRAS JUARGON, S.L.", 50, 80);
    doc.text("CIF: B-42511709", 50, 100);
    doc.text("LA CANTIDAD: " + anticipation.quantity + "€", 50, 120);
    doc.text("CONCEPTO DE: " + impression.concept, 50, 140, { maxWidth: 300 });
    doc.text("CANTIDAD: //" + anticipation.quantity + "€//", 50, 190);
    doc.text(
      "IBI, a " +
        new Date().getDate() +
        "/" +
        (new Date().getMonth() + 1) +
        "/" +
        new Date().getFullYear(),
      320,
      190
    );
    doc.text("FDO: " + anticipation.employee.name.toUpperCase(), 220, 230);
  });
  doc.save(
    "anticipos_" + impression.date_start + "a" + impression.date_end + ".pdf"
  );
};

export const createPDFCustom = (impression) => {
  var doc = new jsPDF("p", "px", "a4", false);
  for (let [index, employee] of impression.employees.entries()) {
    index > 0 && doc.addPage();
    var image = new Image();
    image.src = logo;
    doc.addImage(image, 330, 35, 75, 20);
    doc.setFontSize(20);
    doc.text(employee.name, 50, 50);
    doc.setFontSize(10);
    doc.text("RECIBÍ DE: ESTRUCTURAS Y OBRAS JUARGON, S.L.", 50, 80);
    doc.text("CIF: B-42511709", 50, 100);
    doc.text("LA CANTIDAD: " + (impression.quantity ?? 0) + "€", 50, 120);
    doc.text("CONCEPTO DE: " + impression.concept, 50, 140, { maxWidth: 300 });
    doc.text(
      "IBI, a " +
        new Date().getDate() +
        "/" +
        (new Date().getMonth() + 1) +
        "/" +
        new Date().getFullYear(),
      320,
      190
    );
    doc.text("FDO: " + employee.name.toUpperCase(), 220, 230);
  }
  doc.save(
    "personalizado_" +
      impression.date_start +
      "a" +
      impression.date_end +
      ".pdf"
  );
};

export const createPDFCostsHours = (info, impression) => {
  var doc = new jsPDF("p", "px", "a4", false);
  info?.info?.forEach((information, index) => {
    index > 0 && doc.addPage();
    var image = new Image();
    image.src = logo;
    doc.addImage(image, 330, 35, 75, 20);
    doc.setFontSize(20);
    doc.text(information.employee.name, 50, 50);
    doc.setFontSize(10);
    doc.text(
      "HORAS TRABAJADAS DESDE " +
        formatDate(impression.date_start) +
        " HASTA " +
        formatDate(impression.date_end),
      50,
      80
    );
    doc.setFont(undefined, "bold");
    doc.text("FECHA", 60, 100);
    doc.text("HORAS", 120, 100);
    doc.text("CIUDAD", 200, 100);
    doc.text("OBSERVACIONES", 260, 100);
    doc.setFont(undefined, "normal");
    information.costs.forEach((cost, i) => {
      doc.text(formatDate(cost.date), 60, 118 + 17 * i);
      doc.text(cost.hours.toString() + " - " + cost.type, 120, 118 + 17 * i);
      doc.text(cost.name_city, 200, 118 + 17 * i);
      doc.text(cost.observations ? cost.observations : "", 260, 118 + 17 * i);
    });
  });
  doc.save(
    "horas_" + impression.date_start + "a" + impression.date_end + ".pdf"
  );
};
