global.rute = "https://api.gaymar.info/";
global.pages = [
  {
    id: 0,
    name: "Inicio",
    ref: "/home",
  },
  {
    id: 1,
    name: "Costes",
    ref: "/costs",
  },
  {
    id: 2,
    name: "Jornales",
    ref: "/payments",
  },
  {
    id: 3,
    name: "Trabajadores",
    ref: "/employees",
  },
  {
    id: 4,
    name: "Obras",
    ref: "/constructions",
  },
  {
    id: 5,
    name: "Imprimir",
    ref: "/print",
  },
];
