import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import { Grid } from "@mui/material";
import React, { useState } from "react";
import CostsDiets from "./components/costs_diets/costs_diets";
import CostsHours from "./components/costs_hours/costs_hours";
import CostsOthers from "./components/costs_others/costs_others";
import CostsTab from "./components/costs_tab/costs_tab";

const Costs = () => {
  const [selected, setSelected] = useState(1);
  const selectTab = () => {
    switch (selected) {
      case 1:
        return <CostsHours />;
      case 2:
        return <CostsDiets />;
      case 3:
        return <CostsOthers />;

      default:
        break;
    }
  };

  return (
    <Grid container p={2}>
      <Grid item xs={12} className="row-start row-center">
        <EuroSymbolIcon className="logo-title" />
        <h1 className="m0">Costes</h1>
      </Grid>
      <Grid item xs={12}>
        <CostsTab selected={selected} setSelected={setSelected} />
      </Grid>
      <Grid item xs={12} mt={1}>
        {selectTab()}
      </Grid>
    </Grid>
  );
};

export default Costs;
