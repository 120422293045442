import { Button, IconButton } from "@mui/material";
import React from "react";

const GButton = (props) => {
  return props.iconButton ? (
    <IconButton
      id={props.id}
      style={{ color: props.color ?? "#808080" }}
      onClick={props.onClick}
      className={
        props.className ? "icon-button " + props.className : "icon-button"
      }
    >
      {props.icon}
    </IconButton>
  ) : (
    <Button
      id={props.id}
      type={props.type ?? "button"}
      variant="contained"
      style={props.style ?? null}
      disabled={props.disabled ?? false}
      onClick={props.onClick ?? null}
      endIcon={props.icon ?? null}
      sx={{
        backgroundColor: props.backgroundColor ?? "#808080",
        color: props.color ?? "#ffffff",
        "&:hover": {
          backgroundColor: props.backgroundColorHover ?? "#808080",
        },
      }}
      className={props.className ?? null}
    >
      {props.text ?? null}
    </Button>
  );
};

export default GButton;
