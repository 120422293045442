import axios from "axios";
import { getToken } from "../common/common";
import "../config";

export const loginUser = async (credentials) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  };
  return await axios
    .post(global.rute + "user/login", credentials, config)
    .then((response) => {
      if (response.data.data) {
        sessionStorage.setItem("token", response.data.data.token);
        sessionStorage.setItem("user", response.data.data.user);
      }
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getTokenStatus = async () => {
  if (!getToken()) {
    return false;
  }

  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .get(global.rute + "user/token-expired", config)
    .then((response) => {
      if (response) return response.data;
    })
    .catch((err) => {
      console.log(err);
      return false;
    });
};
