import SendIcon from "@mui/icons-material/Send";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import GAutocomplete from "../../../../facade/g-autocomplete";
import GButton from "../../../../facade/g-button";
import GDate from "../../../../facade/g-date";
import GInput from "../../../../facade/g-input";
import GModal from "../../../../facade/g-modal";
import { getEmployees } from "../../../../services/employees";
import { getMonths, getPaymentTypes } from "../../../../services/masters";
import { createPayments } from "../../../../services/payments";

const INITIAL_FORM_STATE = {
  date: new Date().toISOString().substring(0, 10),
  employee: null,
  type: null,
  quantity: null,
  month: null,
  year: null,
  observations: null,
};

const years = [
  {
    id: 2022,
    name: "2022",
  },
  {
    id: 2023,
    name: "2023",
  },
  {
    id: 2024,
    name: "2024",
  },
  {
    id: 2025,
    name: "2025",
  },
  {
    id: 2026,
    name: "2026",
  },
];

const ModalCreatePayment = (props) => {
  const [payment, setPayment] = useState(INITIAL_FORM_STATE);
  const [payment_types, setPaymentTypes] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [months, setMonths] = useState([]);

  const handleChange = (event) => {
    setPayment((prev_values) => ({
      ...prev_values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleChangeDate = (e) => {
    const date = e.target.value === "" ? null : e.target.value;
    const month_formatted =
      date?.split("-")[1].length > 1
        ? date?.split("-")[1][1]
        : date?.split("-")[1];
    setPayment({
      ...payment,
      year:
        years.find((year) => year.id.toString() === date?.split("-")[0]) ??
        null,
      month:
        months.find((month) => month.id.toString() === month_formatted) ?? null,
      date,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    createPayments({ ...payment, year: payment.year.id }).then((response) => {
      setPayment(INITIAL_FORM_STATE);
      props.onClose({
        ...payment,
        id: response.payment.id,
        year: payment.year.id,
      });
    });
  };

  useEffect(() => {
    props.open && getPaymentTypes().then((pts) => setPaymentTypes(pts));
    props.open && getMonths().then((ms) => setMonths(ms));
    props.open && getEmployees(1).then((ems) => setEmployees(ems));
  }, [props.open]);

  return (
    <GModal
      open={props.open}
      onClose={() => {
        props.onClose();
        setPayment(INITIAL_FORM_STATE);
      }}
      size={"large"}
      content={
        <form onSubmit={handleSubmit} method="post">
          <Grid
            container
            spacing={2}
            className="w-100 create-payment-container-modal"
          >
            <Grid item xs={12} md={10}>
              <GAutocomplete
                multiple={false}
                id="employee"
                label="Trabajador"
                value={payment.employee || null}
                options={employees}
                required={true}
                onChange={(e, value) => {
                  setPayment({ ...payment, employee: value });
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <GInput
                id="quantity"
                name="quantity"
                label="Cantidad"
                required={true}
                type={"number"}
                value={payment.quantity}
                onChange={(e) => handleChange(e)}
                className="w-100"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GDate
                id="date"
                name="date"
                label="Fecha de entega"
                required={true}
                value={payment.date}
                onChange={handleChangeDate}
                className="w-100"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <GAutocomplete
                multiple={false}
                id="month"
                label="Mes"
                value={payment.month || null}
                options={months}
                required={true}
                onChange={(e, value) => {
                  setPayment({ ...payment, month: value });
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <GAutocomplete
                multiple={false}
                id="year"
                label="Año"
                value={payment.year || null}
                options={years}
                required={true}
                onChange={(e, value) => {
                  setPayment({ ...payment, year: value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <GAutocomplete
                multiple={false}
                id="type"
                label="Tipo"
                value={payment.type || null}
                options={payment_types}
                required={true}
                onChange={(e, value) => {
                  setPayment({ ...payment, type: value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <GInput
                id="observations"
                name="observations"
                label="Observaciones"
                required={false}
                multiline={true}
                rows={4}
                value={payment.observations}
                onChange={(e) => handleChange(e)}
                className="w-100"
              />
            </Grid>
            <Grid item xs={12} className="w-100 row-end">
              <GButton
                id={"send-button"}
                text={"Crear pago"}
                icon={<SendIcon />}
                type={"submit"}
                iconButton={false}
              />
            </Grid>
          </Grid>
        </form>
      }
      title={"Crear nuevo pago"}
    />
  );
};

export default ModalCreatePayment;
