import PostAddIcon from "@mui/icons-material/PostAdd";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import GButton from "../../../../facade/g-button";
import { getCosts } from "../../../../services/costs";
import "../../costs.scss";
import CostOthersGrid from "./cost_others_grid/cost_others_grid";
import ModalCreateCostOthers from "./modal_create_cost_others/modal_create_cost_others";
import ModalDeleteCostOthers from "./modal_delete_cost_others/modal_delete_cost_others";
import ModalUpdateCostOthers from "./modal_update_cost_others/modal_update_cost_others";

const CostOthers = () => {
  const [costs, setCosts] = useState([]);
  const [open_create_cost_others, setOpenCreateCostOthers] = useState(false);
  const [selected_cost_others, setSelectedCostOthers] = useState();
  const [open_delete_cost_others, setOpenDeleteCostOthers] = useState(false);
  const [open_update_cost_others, setOpenUpdateCostOthers] = useState(false);

  const handleCloseCreateCost = (total) => {
    total && getCosts(3).then((costs) => setCosts(costs));
    setOpenCreateCostOthers(false);
  };

  const handleCloseDeleteCost = (id) => {
    id && setCosts(costs.filter((cos) => cos.id !== id));
    setOpenDeleteCostOthers(false);
  };

  const handleCloseUpdateCost = (cost) => {
    cost && setCosts(costs.map((cos) => (cost.id === cos.id ? cost : cos)));
    setOpenUpdateCostOthers(false);
  };

  useEffect(() => {
    getCosts(3).then((costs) => setCosts(costs));
  }, []);

  return (
    <Grid container rowSpacing={2} p={2}>
      <Grid item xs={12} className="row-end">
        <GButton
          id={"create_cost"}
          text={"Crear coste"}
          icon={<PostAddIcon />}
          onClick={() => setOpenCreateCostOthers(true)}
        />
      </Grid>
      <Grid item xs={12} className="column-center">
        <CostOthersGrid
          costs={costs}
          setSelectedCostOthers={setSelectedCostOthers}
          setOpenUpdateCostOthers={setOpenUpdateCostOthers}
          setOpenDeleteCostOthers={setOpenDeleteCostOthers}
        />
      </Grid>
      <ModalCreateCostOthers
        open={open_create_cost_others}
        onClose={handleCloseCreateCost}
      />
      <ModalDeleteCostOthers
        open={open_delete_cost_others}
        onClose={handleCloseDeleteCost}
        cost={selected_cost_others}
      />
      <ModalUpdateCostOthers
        open={open_update_cost_others}
        onClose={handleCloseUpdateCost}
        cost={selected_cost_others}
      />
    </Grid>
  );
};

export default CostOthers;
