import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { Grid } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import React, { useEffect, useState } from "react";
import GButton from "../../facade/g-button";
import { getEmployees } from "../../services/employees";
import GridEmployees from "./components/employees_grid/employees_grid";
import ModalCreateEmployee from "./components/modal_create_employee/modal_create_employee";
import ModalUpdateEmployee from "./components/modal_update_employee/modal_update_employee";
import "./employees.scss";

const Employees = () => {
  const [employees, setEmployees] = useState([]);
  const [selected_employee, setSelectedEmployee] = useState();
  const [open_create_employee, setOpenCreateEmployee] = useState(false);
  const [open_update_employee, setOpenUpdateEmployee] = useState(false);

  const handleCloseCreateEmployee = (employee) => {
    employee &&
      setEmployees(
        [{ ...employee, name_job: employee.job.name }].concat(employees)
      );
    setOpenCreateEmployee(false);
  };

  const handleCloseUpdateEmployee = (employee) => {
    employee &&
      setEmployees(
        employees.map((emp) => (emp.id === employee.id ? employee : emp))
      );
    setOpenUpdateEmployee(false);
  };

  useEffect(() => {
    getEmployees().then((empls) => setEmployees(empls));
  }, []);

  return (
    <Grid container rowSpacing={2} p={2}>
      <Grid item xs={12} md={6} className="row-start row-center">
        <PeopleIcon className="logo-title" />
        <h1 className="m0">Trabajadores</h1>
      </Grid>
      <Grid item xs={12} md={6} className="row-end">
        <GButton
          id={"Create"}
          text={"Crear Trabajador"}
          icon={<PersonAddAltIcon />}
          onClick={() => setOpenCreateEmployee(true)}
        />
      </Grid>
      <Grid item xs={12} className="column-center">
        <GridEmployees
          employees={employees}
          openModalUpdate={open_update_employee}
          setOpenUpdateEmployee={setOpenUpdateEmployee}
          setSelectedEmployee={setSelectedEmployee}
          setEmployees={setEmployees}
        />
      </Grid>
      <ModalCreateEmployee
        open={open_create_employee}
        onClose={handleCloseCreateEmployee}
      />
      <ModalUpdateEmployee
        open={open_update_employee}
        onClose={handleCloseUpdateEmployee}
        employee={selected_employee}
      />
    </Grid>
  );
};

export default Employees;
