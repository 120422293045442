import axios from "axios";
import { getToken, launchNotifcation } from "../common/common";
import "../config";

export const getPayments = async () => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .get(global.rute + "payments", config)
    .then((response) => {
      if (response) return response.data.data.payments;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const createPayments = async (payment) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .post(global.rute + "payments", payment, config)
    .then((response) => {
      if (response) {
        launchNotifcation("Pago registrado con éxito.", "success");
        return response.data.data;
      }
    })
    .catch((err) => {
      launchNotifcation("¡Ups! Se ha producido un error...", "danger");
      console.log(err);
      return err;
    });
};

export const getPayment = async (id) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .get(global.rute + "payments/" + id, config)
    .then((response) => {
      if (response) return response.data.data.payment;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const updatePayment = async (payment) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .put(global.rute + "payments", payment, config)
    .then((response) => {
      if (response) {
        launchNotifcation("Pago actualizado con éxito.", "success");
        return response.data.data.payment;
      }
    })
    .catch((err) => {
      launchNotifcation("¡Ups! Se ha producido un error...", "danger");
      console.log(err);
      return err;
    });
};

export const deletePayments = async (id) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .delete(global.rute + "payments/" + id, config)
    .then((response) => {
      launchNotifcation("Pago eliminado con éxito.", "success");
      if (response) return response.data.data;
    })
    .catch((err) => {
      launchNotifcation("¡Ups! Se ha producido un error...", "danger");
      console.log(err);
      return err;
    });
};
