import { TextField } from "@mui/material";
import React from "react";

const GInput = (props) => {
  return (
    <TextField
      label={props.label ?? "null"}
      value={props.value ?? ""}
      id={props.id}
      name={props.name}
      type={props.type ?? "text"}
      variant="filled"
      rows={props.rows ?? 1}
      multiline={props.multiline ?? false}
      InputProps={{
        startAdornment: props.startAdornment ?? null,
        endAdornment: props.endAdornment ?? null,
        color: "dark",
      }}
      InputLabelProps={{ style: { color: props.colorLabel ?? "#a6a6a6" } }}
      onChange={props.onChange}
      required={props.required ?? false}
      className={props.className ?? null}
    />
  );
};

export default GInput;
