import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Grid } from "@mui/material";
import React from "react";
import GButton from "../../../../facade/g-button";
import "../../costs.scss";

export const options = [
  { id: 1, name: "Horas", icon: <AccessAlarmIcon /> },
  { id: 2, name: "Dietas", icon: <FastfoodIcon /> },
  { id: 3, name: "Varios", icon: <MoreHorizIcon /> },
];

const CostsTab = (props) => {
  return (
    <Grid container p={1} spacing={2}>
      {options.map((opt) => (
        <Grid item xs={12 / options.length} key={opt.id}>
          <div
            className={
              props.selected === opt.id ? "tab-costs selected" : "tab-costs"
            }
            onClick={() => props.setSelected(opt.id)}
          >
            <p>{opt.name}</p>
            <GButton
              iconButton={true}
              id={opt.name}
              icon={opt.icon}
              color={props.selected === opt.id && "#000"}
              onClick={() => props.setSelected(opt.id)}
            />
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default CostsTab;
