import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import React, { useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import { BAR_CHART_OPTIONS } from "../../../../common/common";
import { useNavigate } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const PaymentsHome = (props) => {
  const [months, setMonths] = useState([]);
  const [payments, setPayments] = useState([]);
  const navigate = useNavigate();

  const calculateValues = () => {
    return months.map(
      (m) => payments.find((c) => m.id === c.id_month)?.quantity ?? 0
    );
  };
  const labels = months?.map((m) => m.name);
  const data = {
    labels,
    datasets: [
      {
        label: "Cantidad",
        data: calculateValues(),
        backgroundColor: "#669978",
      },
    ],
  };

  useMemo(() => {
    setPayments(props.payments);
    setMonths(props.months);
  }, [props]);

  return (
    <div
      className="home-container"
      onClick={() => {
        navigate("/payments", { replace: true });
      }}
    >
      <div className="home-header">
        <p className="home-title">JORNALES</p>
        <PointOfSaleIcon className="home-icon" />
      </div>
      <Bar
        className="chart-max-heigh"
        options={{
          ...BAR_CHART_OPTIONS,
          plugins: {
            ...BAR_CHART_OPTIONS.plugins,
            title: {
              display: true,
              text: "Cantidad (€) otorgada por mes en " + new Date().getFullYear(),
            },
          },
        }}
        data={data}
      />
    </div>
  );
};

export default PaymentsHome;
