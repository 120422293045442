import { CHANGE_DATA, INIT_DATA, CLEAR_DATA } from "../reducers/user-reducer";

export const initData = () => ({
  type: INIT_DATA,
});

export const changeUser = (data) => ({
  type: CHANGE_DATA,
  payload: data,
});

export const clearUser = () => ({
  type: CLEAR_DATA,
});
