import SendIcon from "@mui/icons-material/Send";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import GButton from "../../../../facade/g-button";
import GInput from "../../../../facade/g-input";
import GModal from "../../../../facade/g-modal";
import { updateCustomer } from "../../../../services/customers";

const INITIAL_FORM_STATE = {
  name: null,
};

const ModalUpdateCustomer = (props) => {
  const [customer, setCustomer] = useState(INITIAL_FORM_STATE);

  useEffect(() => {
    props.open && setCustomer(props.customer);
  }, [props.open]);

  const handleChange = (event) => {
    setCustomer((prev_values) => ({
      ...prev_values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateCustomer(customer).then((cust) => {
      props.onClose(customer);
    });
  };

  return (
    <GModal
      open={props.open}
      onClose={props.onClose}
      size={"small"}
      content={
        <form onSubmit={handleSubmit} method="post">
          <Grid
            container
            spacing={2}
            className="w-100 create-employee-container-modal"
          >
            <Grid item xs={12} md={12}>
              <GInput
                id="name"
                name="name"
                label="Nombre"
                required={true}
                value={customer.name}
                onChange={(e) => handleChange(e)}
                className="w-100"
              />
            </Grid>
            <Grid item xs={12} className="w-100 row-end">
              <GButton
                id={"send-button"}
                text={"Editar cliente"}
                icon={<SendIcon />}
                type={"submit"}
                iconButton={false}
              />
            </Grid>
          </Grid>
        </form>
      }
      title={"Editar cliente: " + props.customer?.name}
    />
  );
};

export default ModalUpdateCustomer;
