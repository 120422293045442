import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getCalculationHome } from "../../services/calculation";
import { getMonths } from "../../services/masters";
import { LocalPrintshop } from "@mui/icons-material";
import CostsHome from "./components/costs/costs_home";
import EmployeesHome from "./components/employees/employees_home";
import PaymentsHome from "./components/payments/payments_home";
import "./home.scss";
import ConstructionHome from "./components/constructions/constructions_home";
import { useNavigate } from "react-router-dom";
import GButton from "../../facade/g-button";

const INITIAL_FORM_STATE = {
  employees: null,
  constructions: null,
  costs: [],
  payments: [],
};

const Home = () => {
  const [calculation, setCalculation] = useState(INITIAL_FORM_STATE);
  const [months, setMonths] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getCalculationHome().then((calculation) => setCalculation(calculation));
    getMonths().then((months) => setMonths(months));
  }, []);

  return (
    <div className="home-container-parent">
      <GButton
        iconButton={true}
        id={"print"}
        className="print-button-home"
        color="white"
        icon={<LocalPrintshop />}
        onClick={() => {
          navigate("/print", { replace: true });
        }}
      />
      <Grid container spacing={2} p={2}>
        <Grid item xs={12} md={6}>
          <EmployeesHome employees={calculation.employees} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ConstructionHome
            months={months}
            constructions={calculation.constructions}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CostsHome months={months} costs={calculation.costs} />
        </Grid>
        <Grid item xs={12} md={6}>
          <PaymentsHome months={months} payments={calculation.payments} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
