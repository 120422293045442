import SendIcon from "@mui/icons-material/Send";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import GButton from "../../../../facade/g-button";
import GInput from "../../../../facade/g-input";
import GModal from "../../../../facade/g-modal";
import { createConstructions } from "../../../../services/constructions";
import { getCities } from "../../../../services/masters";
import { getCustomers } from "../../../../services/customers";
import GAutocomplete from "../../../../facade/g-autocomplete";

const INITIAL_FORM_STATE = {
  name: null,
  location: null,
  budget: null,
  city: null,
  customer: null,
};

const ModalCreateConstruction = (props) => {
  const [construction, setConstruction] = useState(INITIAL_FORM_STATE);
  const [cities, setCities] = useState([]);
  const [customers, setCustomers] = useState([]);

  const handleChange = (event) => {
    setConstruction((prev_values) => ({
      ...prev_values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    createConstructions(construction).then((response) => {
      setConstruction(INITIAL_FORM_STATE);
      props.onClose({ ...construction, id: response.construction.id });
    });
  };

  useEffect(() => {
    props.open && getCities().then((cities) => setCities(cities));
    props.open && getCustomers().then((cust) => setCustomers(cust));
  }, [props.open]);

  return (
    <GModal
      open={props.open}
      onClose={props.onClose}
      size={"large"}
      content={
        <form onSubmit={handleSubmit} method="post">
          <Grid
            container
            spacing={2}
            className="w-100 create-employee-container-modal"
          >
            <Grid item xs={12} md={10}>
              <GInput
                id="name"
                name="name"
                label="Nombre"
                required={true}
                value={construction.name}
                onChange={(e) => handleChange(e)}
                className="w-100"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <GInput
                id="budget"
                name="budget"
                label="Presupuesto"
                required={true}
                type={"number"}
                value={construction.budget}
                onChange={(e) => handleChange(e)}
                className="w-100"
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <GInput
                id="location"
                name="location"
                label="Dirección"
                required={true}
                value={construction.location}
                onChange={(e) => handleChange(e)}
                className="w-100"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <GAutocomplete
                multiple={false}
                id="job"
                label="Municipio"
                value={construction.city || null}
                options={cities}
                required={true}
                onChange={(e, value) => {
                  setConstruction({ ...construction, city: value });
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <GAutocomplete
                multiple={false}
                id="job"
                label="Cliente"
                value={construction.customer || null}
                options={customers}
                required={true}
                onChange={(e, value) => {
                  setConstruction({ ...construction, customer: value });
                }}
              />
            </Grid>
            <Grid item xs={12} className="w-100 row-end">
              <GButton
                id={"send-button"}
                text={"Crear obra"}
                icon={<SendIcon />}
                type={"submit"}
                iconButton={false}
              />
            </Grid>
          </Grid>
        </form>
      }
      title={"Crear Obra"}
    />
  );
};

export default ModalCreateConstruction;
