import PeopleIcon from "@mui/icons-material/People";
import React, { useMemo, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { BAR_CHART_OPTIONS } from "../../../../common/common";
import { useNavigate } from "react-router-dom";

ChartJS.register(ArcElement, Tooltip, Legend);

export function App() {}

const EmployeesHome = (props) => {
  const [employees, setEmployees] = useState([]);
  const navigate = useNavigate();

  const data = {
    labels: employees?.map((e) => e.job.name),
    datasets: [
      {
        label: "Trabajadores",
        data: employees?.map((e) => e.total_employees),
        backgroundColor: ["#749966", "#a7a439", "#cf8f30", "#cf3030"],
      },
    ],
  };

  useMemo(() => {
    setEmployees(props.employees);
  }, [props]);

  return (
    <div
      className="home-container"
      onClick={() => {
        navigate("/employees", { replace: true });
      }}
    >
      <div className="home-header">
        <p className="home-title">Trabajadores</p>
        <PeopleIcon className="home-icon" />
      </div>
      <Doughnut
        data={data}
        className="chart-max-heigh"
        options={{
          ...BAR_CHART_OPTIONS,
          plugins: {
            ...BAR_CHART_OPTIONS.plugins,
            title: {
              display: true,
              text: "Trabajadores activos",
            },
          },
        }}
      />
    </div>
  );
};

export default EmployeesHome;
