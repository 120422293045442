import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import SendIcon from "@mui/icons-material/Send";
import { Grid } from "@mui/material";
import GButton from "../../../../../facade/g-button";
import GModal from "../../../../../facade/g-modal";
import { deleteCost } from "../../../../../services/costs";

const ModalDeleteCostDiets = (props) => {

  const deleteCostDiet = (id) => {
    deleteCost(2, id).then((ct) => {
      props.onClose(id);
    });
  };

  return (
    <GModal
      open={props.open}
      onClose={props.onClose}
      size={"small"}
      content={
        <Grid
          container
          spacing={2}
          className="w-100 create-employee-container-modal"
        >
          <Grid item xs={12} className="w-100 row-center">
            <p className="p-text-delete">
              ¿Estás seguro/a de que quieres eliminar el coste del trabajador{" "}
              <strong>{props.cost?.employee.name}</strong> referido al día{" "}
              <strong className="date-delete-cost">
                {props.cost?.date.substring(0, 10)}
              </strong>
              ?
            </p>
          </Grid>
          <Grid item xs={12} className="w-100 row-space-between">
            <GButton
              id={"cancel-button"}
              text={"Cancelar"}
              icon={<KeyboardReturnIcon />}
              iconButton={false}
              onClick={() => {
                props.setOpenUpdateConstruction(true);
              }}
            />
            <GButton
              id={"send-button"}
              text={"Aceptar"}
              icon={<SendIcon />}
              iconButton={false}
              onClick={() => {
                deleteCostDiet(props.cost?.id);
              }}
            />
          </Grid>
        </Grid>
      }
      title={"Eliminar otro tipo de coste"}
    />
  );
};

export default ModalDeleteCostDiets;
