export const INIT_DATA = "INIT_DATA";
export const CHANGE_DATA = "CHANGE_DATA";
export const CLEAR_DATA = "CLEAR_DATA";

export const initialState = {
  token: "",
  user: {},
};

export function userReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_DATA: {
      return action.payload;
    }
    case CLEAR_DATA: {
      return initialState;
    }
    default:
      return state;
  }
}
