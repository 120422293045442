import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import Menu from "./menu/menu";
import Constructions from "./pages/constructions/constructions";
import Costs from "./pages/costs/costs";
import Employees from "./pages/employees/employees";
import Home from "./pages/home/home";
import Login from "./pages/login/login.jsx";
import Payments from "./pages/payments/payments";
import Print from "./pages/print/print";
import { getTokenStatus } from "./services/users";
import { store } from "./store/store";

function App() {
  const state = store.getState();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getTokenStatus().then((res) => {
      res === false && navigate("/login", { replace: true });
    });
  }, [navigate, state.user.token]);

  return (
    <div className="App">
      <ReactNotifications />{" "}
      {!window.location.href.includes("login") && (
        <Menu open={open} setOpen={setOpen} />
      )}
      <Box
        component="main"
        className={!window.location.href.includes("login") && "main"}
        sx={{
          width: window.location.href.includes("login")
            ? "100%"
            : open
            ? "calc(100% - 240px)"
            : "calc(100% - 65px)",
        }}
      >
        <Routes>
          {" "}
          {/* <Route path="/" element={<Home />} /> */}{" "}
          <Route path="/login" element={<Login />} />{" "}
          <Route path="/costs" element={<Costs />} />{" "}
          <Route path="/payments" element={<Payments />} />{" "}
          <Route path="/employees" element={<Employees />} />{" "}
          <Route path="/constructions" element={<Constructions />} />{" "}
          <Route path="/print" element={<Print />} />{" "}
          <Route path="/" element={<Home />} />{" "}
          <Route path="/home" element={<Home />} />{" "}
        </Routes>{" "}
      </Box>{" "}
    </div>
  );
}

export default App;
