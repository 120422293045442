import React, { useMemo, useState } from "react";
import { Box, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import GButton from "../../../../facade/g-button";
import "../../payments.scss";
import GDate from "../../../../facade/g-date";
import GAutocomplete from "../../../../facade/g-autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";

const INIT_FILTERS = {
  employees: [],
  types: [],
  date: null,
  months: [],
  years: [],
};

const GridPayments = (props) => {
  const columns = [
    {
      field: "name_employee",
      headerName: "Trabajador",
      flex: 0.15,
      minWidth: 130,
      renderCell: (val) => val.row.employee.name,
    },
    {
      field: "quantity",
      headerName: "Cantidad",
      renderCell: (val) => val.row.quantity + " €",
      flex: 0.1,
      minWidth: 100,
    },
    {
      field: "type",
      headerName: "Cantidad",
      renderCell: (val) => val.row.type.name,
      flex: 0.1,
      minWidth: 100,
    },
    {
      field: "date",
      headerName: "Fecha de pago",
      renderCell: (val) => val.row.date.substring(0, 10),
      flex: 0.1,
      minWidth: 100,
    },
    {
      field: "name_month",
      headerName: "Mes ref.",
      renderCell: (val) => val.row.month.name,
      flex: 0.1,
      minWidth: 100,
    },
    {
      field: "year",
      headerName: "Año ref.",
      flex: 0.05,
      minWidth: 100,
    },
    {
      field: "observations",
      headerName: "Observaciones",
      flex: 0.3,
      minWidth: 100,
    },
    {
      field: "actions",
      headerName: "Opciones",
      flex: 0.1,
      renderCell: (val) => {
        return (
          <div className="row-center">
            <GButton
              iconButton={true}
              id={"Edit"}
              icon={<EditIcon />}
              onClick={() => {
                props.setSelectedPayment(val.row);
                props.setOpenUpdatePayment(true);
              }}
            />
            <GButton
              iconButton={true}
              color="#ff4d4d"
              id={"Delete"}
              icon={<DeleteIcon />}
              onClick={() => {
                props.setSelectedPayment(val.row);
                props.setOpenDeletePayment(true);
              }}
            />
          </div>
        );
      },
    },
  ];

  const [payments, setPayments] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const [types, setTypes] = useState([]);
  const [filters, setFilters] = useState(INIT_FILTERS);

  const getEmployeesFromPayments = () => {
    if (props.payments.length > 0) {
      const emps = props.payments.map((p) => p.employee);
      return [...new Map(emps.map((e) => [e.id, e])).values()];
    } else return [];
  };

  const getTypesFromPayments = () => {
    if (props.payments.length > 0) {
      const tps = props.payments.map((p) => p.type);
      return [...new Map(tps.map((t) => [t.id, t])).values()];
    } else return [];
  };

  const getMonthsFromPayments = () => {
    if (props.payments.length > 0) {
      const ms = props.payments.map((p) => p.month);
      return [...new Map(ms.map((m) => [m.id, m])).values()];
    } else return [];
  };

  const getYearsFromPayments = () => {
    if (props.payments.length > 0) {
      const ys = props.payments.map((p) => ({ id: p.year, name: p.year }));
      return [...new Map(ys.map((y) => [y.id, y])).values()];
    } else return [];
  };

  const applyFilters = () => {
    var payments_filtered = props.payments;
    if (filters.months.length > 0) {
      payments_filtered = payments_filtered.filter((pf) =>
        filters.months.some((c) => c.id === pf.month.id)
      );
    }
    if (filters.years.length > 0) {
      payments_filtered = payments_filtered.filter((pf) =>
        filters.years.some((c) => c.id === pf.year)
      );
    }
    if (filters.employees.length > 0) {
      payments_filtered = payments_filtered.filter((pf) =>
        filters.employees.some((c) => c.id === pf.employee.id)
      );
    }
    if (filters.types.length > 0) {
      payments_filtered = payments_filtered.filter((pf) =>
        filters.types.some((c) => c.id === pf.type.id)
      );
    }
    if (filters.date !== null) {
      payments_filtered = payments_filtered.filter(
        (pf) => filters.date === pf.date.substring(0, 10)
      );
    }
    setPayments(payments_filtered);
  };

  useMemo(() => {
    setEmployees(getEmployeesFromPayments());
    setMonths(getMonthsFromPayments());
    setTypes(getTypesFromPayments());
    setYears(getYearsFromPayments());
    setPayments(props.payments);
  }, [props.payments]);

  useMemo(() => {
    applyFilters();
  }, [filters, props.payments]);

  return (
    <Box
      sx={{
        height: "70vh",
        width: "90%",
      }}
    >
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={3}>
          <GAutocomplete
            multiple={true}
            id="filter_employee"
            label="Trabajadores"
            value={filters.employees || null}
            options={employees}
            onChange={(e, value) => {
              setFilters({ ...filters, employees: value });
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <GAutocomplete
            multiple={true}
            id="filter_type"
            label="Tipo de pago"
            value={filters.types || null}
            options={types}
            onChange={(e, value) => {
              setFilters({ ...filters, types: value });
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <GDate
            id="date"
            name="date"
            label="Fecha"
            required={true}
            value={filters.date}
            onChange={(e) => {
              setFilters({
                ...filters,
                date: e.target.value === "" ? null : e.target.value,
              });
            }}
            className="w-100"
          />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <GAutocomplete
            multiple={true}
            id="filter_month"
            label="Mes"
            value={filters.months || null}
            options={months}
            onChange={(e, value) => {
              setFilters({ ...filters, months: value });
            }}
          />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <GAutocomplete
            multiple={true}
            id="filter_year"
            label="Año"
            value={filters.years || null}
            options={years}
            onChange={(e, value) => {
              setFilters({ ...filters, years: value });
            }}
          />
        </Grid>
      </Grid>
      <DataGrid
        rows={payments || []}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
      />
    </Box>
  );
};

export default GridPayments;
