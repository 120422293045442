import axios from "axios";
import { getToken, launchNotifcation } from "../common/common";
import "../config";

export const getCosts = async (type) => {
  const config = {
    params: {
      type,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .get(global.rute + "costs", config)
    .then((response) => {
      if (response) return response.data.data.costs;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const createCostHour = async (costs) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .post(global.rute + "costs/hour", costs, config)
    .then((response) => {
      if (response) {
        launchNotifcation("Coste/s creado/s con éxito.", "success");
        return response.data.data;
      }
    })
    .catch((err) => {
      launchNotifcation("¡Ups! Se ha producido un error...", "danger");
      console.log(err);
      return err;
    });
};

export const createCostDiet = async (costs) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .post(global.rute + "costs/diet", costs, config)
    .then((response) => {
      if (response) {
        launchNotifcation("Coste/s creado/s con éxito.", "success");
        return response.data.data;
      }
    })
    .catch((err) => {
      launchNotifcation("¡Ups! Se ha producido un error...", "danger");
      console.log(err);
      return err;
    });
};

export const createCostOthers = async (costs) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .post(global.rute + "costs/other", costs, config)
    .then((response) => {
      if (response) {
        launchNotifcation("Coste/s creado/s con éxito.", "success");
        return response.data.data;
      }
    })
    .catch((err) => {
      launchNotifcation("¡Ups! Se ha producido un error...", "danger");
      console.log(err);
      return err;
    });
};

export const deleteCost = async (type, id, cost) => {
  const config = {
    params: {
      type,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .delete(global.rute + "costs/" + id, config)
    .then((response) => {
      launchNotifcation("Coste eliminado con éxito.", "success");
      if (response) return response.data.data;
    })
    .catch((err) => {
      launchNotifcation("¡Ups! Se ha producido un error...", "danger");
      console.log(err);
      return err;
    });
};

export const updateCostOther = async (cost) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .put(
      global.rute + "costs/other",
      { ...cost, date: cost.date?.substring(0, 10) },
      config
    )
    .then((response) => {
      launchNotifcation("Coste actualizado con éxito.", "success");
      if (response) return response.data.data;
    })
    .catch((err) => {
      launchNotifcation("¡Ups! Se ha producido un error...", "danger");
      console.log(err);
      return err;
    });
};

export const updateCostHour = async (cost) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .put(
      global.rute + "costs/hour",
      { ...cost, date: cost.date?.substring(0, 10) },
      config
    )
    .then((response) => {
      launchNotifcation("Coste actualizado con éxito.", "success");
      if (response) return response.data.data;
    })
    .catch((err) => {
      launchNotifcation("¡Ups! Se ha producido un error...", "danger");
      console.log(err);
      return err;
    });
};

export const updateCostDiet = async (cost) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .put(
      global.rute + "costs/diet",
      { ...cost, date: cost.date?.substring(0, 10) },
      config
    )
    .then((response) => {
      launchNotifcation("Coste actualizado con éxito.", "success");
      if (response) return response.data.data;
    })
    .catch((err) => {
      launchNotifcation("¡Ups! Se ha producido un error...", "danger");
      console.log(err);
      return err;
    });
};
