import axios from "axios";
import { getToken, launchNotifcation } from "../common/common";
import "../config";

export const getCustomers = async () => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .get(global.rute + "customers", config)
    .then((response) => {
      if (response) return response.data.data.customers;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const createCustomer = async (customer) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .post(global.rute + "customers", customer, config)
    .then((response) => {
      if (response) {
        launchNotifcation("Cliente creado con éxito.", "success");
        return response.data.data;
      }
    })
    .catch((err) => {
      launchNotifcation("¡Ups! Se ha producido un error...", "danger");
      console.log(err);
      return err;
    });
};

export const updateCustomer = async (customer) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .put(global.rute + "customers", customer, config)
    .then((response) => {
      if (response) {
        launchNotifcation("Cliente actualizado con éxito.", "success");
        return response.data.data.customer;
      }
    })
    .catch((err) => {
      launchNotifcation("¡Ups! Se ha producido un error...", "danger");
      console.log(err);
      return err;
    });
};