import axios from "axios";
import { getToken } from "../common/common";
import "../config";

export const getCalculationHome = async () => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${getToken()}`,
      },
    };
  
    return await axios
      .get(global.rute + "calculation/home", config)
      .then((response) => {
        if (response) return response.data.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };