import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel } from "@mui/material";

export const GCheckBox = (props) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          id={props.id}
          name={props.name}
          checked={props.checked}
          onChange={props.onChange}
          color="default"
        />
      }
      label={props.label}
    />
  );
};

export default GCheckBox;
