import SendIcon from "@mui/icons-material/Send";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import GAutocomplete from "../../../../../facade/g-autocomplete";
import GButton from "../../../../../facade/g-button";
import GDate from "../../../../../facade/g-date";
import GInput from "../../../../../facade/g-input";
import GModal from "../../../../../facade/g-modal";
import { getConstructions } from "../../../../../services/constructions";
import { updateCostDiet } from "../../../../../services/costs";
import { getEmployees } from "../../../../../services/employees";

const INITIAL_FORM_STATE = {
  date: new Date().toISOString().substring(0, 10),
  employee: null,
  construction: null,
  observations: null,
  salary: null,
  id: null,
};

const ModalUpdateCostDiets = (props) => {
  const [cost, setCost] = useState(INITIAL_FORM_STATE);
  const [constructions, setConstructions] = useState([]);
  const [employees, setEmployees] = useState([]);

  const handleChange = (event) => {
    setCost((prev_values) => ({
      ...prev_values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateCostDiet(cost).then((response) => {
      props.onClose(cost);
    });
  };

  useEffect(() => {
    props.open &&
      setCost({
        ...props.cost,
        construction:
          props.cost.construction.id === null ? null : props.cost.construction,
      });
    props.open &&
      getConstructions(1).then((constructions) =>
        setConstructions(constructions)
      );
    props.open && getEmployees().then((employees) => setEmployees(employees));
  }, [props.open]);

  return (
    <GModal
      open={props.open}
      onClose={props.onClose}
      size={"large"}
      content={
        <form onSubmit={handleSubmit} method="post">
          <Grid
            container
            spacing={2}
            className="w-100 create-employee-container-modal"
          >
            <Grid item xs={12}>
              <GAutocomplete
                multiple={false}
                id="employee"
                label="Trabajador"
                value={cost.employee || null}
                options={employees}
                required={true}
                onChange={(e, value) => {
                  setCost({ ...cost, employee: value });
                }}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <GDate
                id="date"
                name="date"
                label="Fecha"
                required={true}
                value={cost.date?.substring(0, 10) || null}
                onChange={(e) => handleChange(e)}
                className="w-100"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <GInput
                id="salary"
                name="salary"
                label="Sueldo"
                required={true}
                value={cost.salary}
                onChange={(e) => handleChange(e)}
                className="w-100"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GAutocomplete
                multiple={false}
                id="construction"
                label="Obra"
                value={cost.construction || null}
                options={constructions}
                required={false}
                onChange={(e, value) => {
                  setCost({ ...cost, construction: value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <GInput
                id="observations"
                name="observations"
                label="Observaciones"
                required={false}
                multiline={true}
                rows={4}
                value={cost.observations}
                onChange={(e) => handleChange(e)}
                className="w-100"
              />
            </Grid>
            <Grid item xs={12} className="w-100 row-end">
              <GButton
                id={"send-button"}
                text={"Editar dieta"}
                icon={<SendIcon />}
                type={"submit"}
                iconButton={false}
              />
            </Grid>
          </Grid>
        </form>
      }
      title={"Editar coste de dieta"}
    />
  );
};

export default ModalUpdateCostDiets;
