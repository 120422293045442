import axios from "axios";
import { getToken, launchNotifcation } from "../common/common";
import "../config";

export const getImpressionTypes = async () => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .get(global.rute + "print/types", config)
    .then((response) => {
      if (response) return response.data.data.impression_types;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const createPDF = async (impression) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  return await axios
    .post(global.rute + "print/pdf", impression, config)
    .then((response) => {
      if (response) return response.data.data;
    })
    .catch((err) => {
      launchNotifcation("¡Ups! Puede que no haya datos...", "danger");
      console.log(err);
      return err;
    });
};
