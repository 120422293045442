import CircleIcon from "@mui/icons-material/Circle";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import GButton from "../../../../facade/g-button";
import { getCosts } from "../../../../services/costs";
import "../../costs.scss";
import CostHoursGrid from "./cost_hours_grid/cost_hours_grid";
import ModalCreateCostHour from "./modal_create_cost_hours/modal_create_cost_hours";
import ModalDeleteCostHours from "./modal_delete_cost_hours/modal_delete_cost_hours";
import ModalUpdateCostHours from "./modal_update_cost_hours/modal_update_cost_hours";

const CostsHours = () => {
  const [costs, setCosts] = useState([]);
  const [open_create_cost_hour, setOpenCreateCostHour] = useState(false);
  const [selected_cost_hours, setSelectedCostHours] = useState();
  const [open_delete_cost_hours, setOpenDeleteCostHours] = useState(false);
  const [open_update_cost_hours, setOpenUpdateCostHours] = useState(false);

  const handleCloseCreateCost = (total) => {
    total && getCosts(1).then((costs) => setCosts(costs));
    setOpenCreateCostHour(false);
  };

  const handleCloseDeleteCost = (id) => {
    id && setCosts(costs.filter((cos) => cos.id !== id));
    setOpenDeleteCostHours(false);
  };

  const handleCloseUpdateCost = (cost) => {
    cost && setCosts(costs.map((cos) => (cost.id === cos.id ? cost : cos)));
    setOpenUpdateCostHours(false);
  };

  useEffect(() => {
    getCosts(1).then((costs) => setCosts(costs));
  }, []);

  return (
    <Grid container rowSpacing={2} p={2}>
      <Grid item xs={12} md={9} className="row-space-around">
        <div className={"row-space-around"}>
          {" "}
          <CircleIcon className="color_1" />
          <p className="m0">Día normal</p>{" "}
        </div>{" "}
        <div className={"row-space-around"}>
          <CircleIcon className="color_2" />
          <p className="m0">Horas extra</p>{" "}
        </div>{" "}
        <div className={"row-space-around"}>
          <CircleIcon className="color_3" />
          <p className="m0">Sábado</p>
        </div>{" "}
        <div className={"row-space-around"}>
          <CircleIcon className="color_4" />
          <p className="m0">Festivo</p>
        </div>{" "}
      </Grid>
      <Grid item xs={12} md={3} className="row-end">
        <GButton
          id={"create_cost"}
          text={"Crear Coste"}
          icon={<PostAddIcon />}
          onClick={() => setOpenCreateCostHour(true)}
        />
      </Grid>
      <Grid item xs={12} className="column-center">
        <CostHoursGrid costs={costs}
        setSelectedCostHours={setSelectedCostHours}
        setOpenUpdateCostHours={setOpenUpdateCostHours}
        setOpenDeleteCostHours={setOpenDeleteCostHours} />
      </Grid>
      <ModalCreateCostHour
        open={open_create_cost_hour}
        onClose={handleCloseCreateCost}
      />
      <ModalDeleteCostHours
        open={open_delete_cost_hours}
        onClose={handleCloseDeleteCost}
        cost={selected_cost_hours}
      />
      <ModalUpdateCostHours
        open={open_update_cost_hours}
        onClose={handleCloseUpdateCost}
        cost={selected_cost_hours}
      />
    </Grid>
  );
};

export default CostsHours;
