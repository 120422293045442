import React from "react";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import GButton from "../../../../facade/g-button";
import CircleIcon from "@mui/icons-material/Circle";
import PanToolIcon from "@mui/icons-material/PanTool";
import "../../employees.scss";
import { updateEmployees } from "../../../../services/employees";

const GridEmployees = (props) => {
  const columns = [
    {
      field: "name",
      headerName: "Nombre",
      flex: 0.3,
      minWidth: 180,
    },
    {
      field: "hour_salary",
      headerName: "Sueldo/hora",
      renderCell: (val) => val.row.hour_salary + " €",
      flex: 0.1,
      minWidth: 100,
    },
    {
      field: "saturday_salary",
      headerName: "Sueldo sábado/hora",
      renderCell: (val) => val.row.saturday_salary + " €",
      flex: 0.1,
      minWidth: 100,
    },
    {
      field: "festive_hour_salary",
      headerName: "Sueldo festivo/hora",
      renderCell: (val) => val.row.festive_hour_salary + " €",
      flex: 0.1,
      minWidth: 100,
    },
    {
      field: "diet_salary",
      headerName: "Dietas",
      renderCell: (val) => val.row.diet_salary + " €",
      flex: 0.1,
      minWidth: 100,
    },
    {
      field: "name_job",
      headerName: "Puesto",
      type: "number",
      flex: 0.15,
      minWidth: 110,
      renderCell: (val) => val.row.job.name,
    },
    {
      field: "active",
      headerName: "Activo",
      type: "number",
      flex: 0.05,
      minWidth: 80,
      renderCell: (val) =>
        val.row.active ? (
          <GButton
            iconButton={true}
            id={"active"}
            icon={<CircleIcon className="circle-color" />}
            onClick={() => {
              handleUpdateEmployee(0, val.row);
            }}
          />
        ) : (
          <GButton
            iconButton={true}
            id={"non_active"}
            icon={<PanToolIcon className="hand-color" />}
            onClick={() => {
              handleUpdateEmployee(1, val.row);
            }}
          />
        ),
    },
    {
      field: "actions",
      headerName: "Opciones",
      flex: 0.1,
      renderCell: (val) => {
        return (
          <div className="row-center">
            <GButton
              iconButton={true}
              id={"Edit"}
              icon={<EditIcon />}
              onClick={() => {
                props.setSelectedEmployee(val.row);
                props.setOpenUpdateEmployee(true);
              }}
            />
          </div>
        );
      },
    },
  ];

  const handleUpdateEmployee = async (active, employee) => {
    updateEmployees({ ...employee, active }).then((emp) => {
      props.setEmployees(
        props.employees.map((emp) =>
          emp.id === employee.id ? { ...employee, active } : emp
        )
      );
    });
  };

  return (
    <Box
      sx={{
        height: "80vh",
        width: "90%",
      }}
    >
      <DataGrid
        rows={props.employees || []}
        columns={columns}
        pageSize={12}
        rowsPerPageOptions={[12]}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
};

export default GridEmployees;
